import React from "react";
import frogs from "../assets/images/KingAndQueen.png"
import { Navbar, Container, Nav } from "react-bootstrap"
import { AiOutlineTwitter, AiOutlineGithub } from "react-icons/ai";
import { RiDiscordFill, RiTelegramFill } from "react-icons/ri";


function Sale() {

    return(
        <>
            <div><h1 className="faqhead text-center">PRESALE ADDRESS </h1>
            </div>
            <div><h1 className="faqhead text-center">5BBQjhBMJWBgm7C9p3bNumLd8yMjNP87N8D4uBsEirtm</h1>
            </div>
            <Navbar bg="" variant="dark" expand="lg">
                <Container fluid>

                    <Navbar.Brand style={{
                        display: 'flex', // Use flexbox
                        justifyContent: 'center', // Center horizontally in the flex container
                        alignItems: 'center', // Center vertically in the flex container
                        width: '100%', // Take full width of the container to center content within it
                        fontFamily: "sans serif",
                        color: "#FFF",
                        fontWeight: "700",
                        fontSize: "26px"
                    }}>
                        <img
                            src={frogs} // Path to your banner image
                            height="640" // Adjust based on your preference
                            width="1840" // Adjust based on your preference
                            alt="Medieval Frogs" // Alternative text for the image
                            style={{
                                maxWidth: '100%',
                                height: 'auto'
                            }} // Ensure image is responsive and maintains aspect ratio
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll"/>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav activeKey={window.location.pathname}
                             className="me-auto"
                             style={{maxHeight: '100px'}}
                             navbarScroll>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="text-center">
                <a target="_blank" href='https://twitter.com/FrogCardsOnSol'>
                    <AiOutlineTwitter size={150} color='white' className='footer-icon'/>
                </a>


            </div>

        </>

    )
}


export default Sale;  
