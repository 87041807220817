const CollectionConfig = {
  tokenName: "Frogs Collection",
  tokenSymbol: "FROG",
  baseMetadataURI: "ipfs://QmdN2LsGe35Skv8HJjjYQqtv2LNs43VS7zxQgcLPwHxYjd/",
  hiddenMetadataUri: "ipfs://QmcSWAAxqfdDGcKNGxKeqRbziCNhuwTv7jRD87EjCn1wvg",
  maxSupply: 10000,
  whitelistSale: {
    price: 0.06969,
    maxMintAmountPerTx: 1,
  },
  preSale: {
    price: 0.07,
    maxMintAmountPerTx: 3,
  },
  publicSale: {
    price: 0.06969,
    maxMintAmountPerTx: 5,
  },
  contractAddress: null,
  marketplaceIdentifier: "my-nft-token",
  whitelistAddresses: [],
  releaseDates: {
    whitelistStart: new Date("2024-03-03 12:00:00"),
    whitelistEnd: new Date("2024-03-03 12:00:00"),
    presaleStart: new Date("2024-03-03 00:00:00"),
    presaleEnd: new Date("2024-03-03 12:00:00"),
    publicSaleOpen: new Date("2024-03-03 12:00:00"),
  },
};

module.exports = { CollectionConfig };
