import React from 'react';
import Account from "./Account";
import { Navbar, Container, Nav } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.css';
import frogLogo from "../assets/images/FrogIsKing.png"


function NavBar() {

    return (
        <>
            <Navbar bg="" variant="dark" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="/" style={{
                        display: 'flex', // Use flexbox
                        justifyContent: 'center', // Center horizontally in the flex container
                        alignItems: 'center', // Center vertically in the flex container
                        width: '100%', // Take full width of the container to center content within it
                        fontFamily: "sans serif", 
                        color: "#FFF", 
                        fontWeight: "700", 
                        fontSize: "26px"
                    }}>
                        <img
                            src={frogLogo} // Path to your banner image
                            height="460" // Adjust based on your preference
                            width="1360" // Adjust based on your preference
                            alt="Medieval Frogs" // Alternative text for the image
                            style={{ maxWidth: '100%', height: 'auto' }} // Ensure image is responsive and maintains aspect ratio
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav activeKey={window.location.pathname}
                            className="me-auto"
                            style={{ maxHeight: '100px' }}
                            navbarScroll>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}



export default NavBar;  