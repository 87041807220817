import React from 'react';
import Typewriter from 'typewriter-effect';
import styled from 'styled-components';
import { Button } from '@mui/material';

function TypeWriterText() {
    return (
        <>
            <Title>
                <h1>Embark upon a quest</h1>  <h1>to unveil an age of</h1> <h1>Unparalleled Chivalry.</h1>
            </Title>
            <ButtonContainer>
                {/* <Button className='bt-linear'
                    variant="contained"
                    color="primary">
                    <a href='#raodmap' style={{ textDecoration: 'none', color: 'white' }}>
                        Explore
                    </a>
                </Button> */}
            </ButtonContainer>
        </>
    )
}

export default TypeWriterText;


const Title = styled.div` /* Changed from h2 to div to contain h1 elements */
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    color: #fff;
    text-transform: capitalize;
    text-align: left;
    width: 100%; /* Full width to utilize available space */
    margin-bottom: 2rem; /* Space below the title */

    h1 {
        font-size: 44px; /* Size for desktop */
        line-height: 1.2; /* Tighter line height for desktop */
        display: inline-block; /* Allow titles to sit next to each other if space allows */
        margin: 0; /* Remove default margin */
        padding: 0; /* Remove default padding */
        white-space: nowrap; /* Prevent breaking inside titles */
    }

    /* Adapt the font size and line height on smaller screens */
    @media (max-width: 70em) {
        text-align: left; /* Center text for mobile */
        h1 {
            margin-left: 0;
            padding-left: 0;
            font-size: 30px; /* Smaller font size for tablets */
        }
    }

    @media (max-width: 48em) {
        text-align: left; /* Center text for mobile */
        h1 {
            
            margin-left: 0;
            padding-left: 0;
            font-size: 24px; /* Even smaller font size for mobile */
            line-height: 1.3; /* Adjust line height for mobile */
        }
    }

    .text-1 {
        color: #5ea425;
    }
`;
const SubTitle = styled.h3`
    line-height: 1.3; /* Adjust line height for better spacing */
    margin-bottom: 2rem; /* Increase space below the subtitle for breathing room */
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
    text-align: left;
    margin-bottom: 1rem;
    width:80%;
    align-self: left;

    @media (max-width: 40em){
        font-size: 16px;
        padding-top: 20px;
    }
    @media (max-width: 48em){
        align-self: center;
        text-align: center;
        margin-bottom: 1.5rem; /* Adjust spacing for mobile */
        padding-top: 20px;
        margin-left: 100px;
    }
`

const ButtonContainer = styled.div`
    width: 80%;
    align-self: flex-start;
    text-align: left;
    padding-top: 20px;

    @media (max-width: 48em){
        align-self: center;
        text-align: center;
        margin-left: 100px;

        button {
            margin: 0 auto;
        }
    }
`
